<template>
  <div>
    <el-row> 
      <el-col :span="24" class="infoTit">
        <span  v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==0"  class="tag bgRed titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==1"  class="tag bgGreen titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.otcType===0"  class="tag bgRed titColor radiusBox">Rx</span>
        <span  v-if="goodsinfo.newStatus==true||goodsinfo.newStatus=='true'"  class="tag bgGreen titColor">新品</span>
        <span  v-if="goodsinfo.medicalInsuranceType>0"  class="tag bgBlue titColor">医保</span>
        <span  v-if="goodsinfo.proprietary==1"   class="tag bgOrange titColor">自营</span>
       <span class="brandName">{{goodsinfo.brandName}} </span>
        <!-- {{ goodsinfo.name }} -->
         <span v-if="goodsinfo.alias==''||goodsinfo.alias==null">{{ goodsinfo.name }}  </span>
         <span v-else>{{ goodsinfo.alias }}  </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg"  v-if="activityType==1">
        <span>{{ goodsinfo.subTitle }}</span>
        <!--   <p v-for="(item, index) in goodsinfo.subTitle.split('；')" :key="index" >{{ item }}</p> -->
      </el-col>
       <el-col :span="24" class="infoMsg"  v-if="activityType==2">
         <!-- 限时购详情 -->
         <span>商品包含：{{ subTitle }}</span>
      </el-col>
    </el-row>
    <el-row v-if="activityType==2">
      <el-col :span="24" class="countdownBox">
        <span class="countdownMsg">限时抢购</span>
        <span class="countdownDiv">
            <ProCountDown :startTime="startTime" :endTime="endTime"></ProCountDown>
        </span>
      </el-col>
    </el-row>
    <el-row class="infoBox">
      <el-col :span="24" class="infoActivity" v-if="activityType==2">
        <span class="infoKey">活动说明:</span>
        <span class="infoDes">{{goodsinfo.description}}</span>
      </el-col>
      <el-col :span="6"  v-if="activityType==1">
        <span class="infoKey">预售价：</span>
        <span class="price"><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":goodsinfo.price }}</span>
      </el-col>
      <el-col :span="6" v-if="activityType==1">
        <span class="infoKey">参考价：</span>￥{{
          goodsinfo.price==="***"?"会员可见":goodsinfo.originalPrice
        }}</el-col
      >
      <el-col :span="6" v-if="activityType==1"><span class="infoKey">毛利率：</span>{{ goodsinfo.grossProfitMargin }}%</el-col>
      <el-col :span="6" v-if="activityType==1"><span class="infoKey">净赚：</span>￥{{ goodsinfo.price==="***"?"会员可见":goodsinfo.earnings }}</el-col>
      <el-col :span="24" class="infoActivity"  v-for="(item,index) in promotionMap" :key="index">
        <span class="infoKey" v-if="index==0">促销活动:</span>
        <span class="infoKey" v-else></span>
        <span class="infoTag">{{item.type}}</span>
        <span class="infoDes">{{item.name}}</span>
      </el-col>
      <!-- <el-col :span="24" class="infoActivity">
        <span class="infoKey"></span>
        <span class="infoTag">免运费</span>
        <span class="infoDes">该商品免运费</span>
      </el-col> -->
    </el-row>
    <el-row  :span="24"  class="infoParameter" v-if="activityType==1">
      <div><span class="infoKey">商品编号:</span><span>{{goodsinfo.productSn }}</span></div>
       <div><span class="infoKey">国药准字:</span><span>{{goodsinfo.approvalNo}}</span></div>
      <div><span class="infoKey">药品规格:</span><span>{{goodsinfo.specification}}</span></div>
      <div><span class="infoKey">件装规格:</span><span>{{goodsinfo.partSpecification}}</span></div>
      <div><span class="infoKey">发货时间:</span><span>{{goodsinfo.presellDeliveryDate}}</span></div>
      <div><span class="infoKey">医保类型:</span><span>{{goodsinfo.medicalInsuranceType===0?'非医保':goodsinfo.medicalInsuranceType===1?'甲类医保':'乙类医保'}}</span></div>
      <!-- <div>
        <span class="infoKey" style="text-indent: 30px;">库存:</span>
          <span v-if="goodsinfo.quantity==-1 || goodsinfo.quantity< Number(goodsinfo.minOrderNum)">备货中</span>
          <span v-if="goodsinfo.quantity>1000">充足</span>
          <span v-if="goodsinfo.quantity<=1000 && goodsinfo.quantity>=Number(goodsinfo.minOrderNum)">{{goodsinfo.quantity}}{{goodsinfo.unit}}</span>
      </div> -->
      <div v-if="goodsinfo.quantity<1 && goodsinfo.deliveryTimeFormat"><span class="infoKey">到货日期:</span><span>{{goodsinfo.deliveryTimeFormat}}</span></div>
      <div  @click="Download(goodsinfo.id)"> <span class="infoKey" >首营资料：</span> <strong class="downBtn">查看/下载</strong></div>
    </el-row>
    <el-row :span="24" class="DecVOBox" v-if="activityType==2 && goodsinfo.numPriceDetailVOList.length>0">
        <div class="DecVOLeft">
          <span><em>满降</em></span>
        </div>
        <div  class="DecVORight" >
          <p   v-for="(item, index) in goodsinfo.numPriceDetailVOList" :key="index">满{{item.num}}盒享{{item.price}}元/盒</p>
        </div>
      </el-row>
    <el-row  :span="24"  class="infoParameter"  v-if="activityType==2">
       <el-col :span="6">
        <span class="infoKey" style="text-indent: 30px;">限购价：</span
        ><span class="price">
           {{ goodsinfo.auditUserStatus===0?"***":comboPrice  | capitalize }}
           </span>
           <del class="del">{{goodsinfo.auditUserStatus===0?"***":goodsinfo.comboOriginalPrice | capitalize}}</del>
        </el-col>
      <el-col :span="6">
        <span class="infoKeys" >限购<span class="price">{{goodsinfo.comboUserTotal}}</span>盒，已购<span class="price">{{goodsinfo.userUsage}}</span>盒</span>
      </el-col>
    </el-row>


    <el-row class="infoNumBox" v-if="activityType==1">
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="goodsinfo.minOrderNum<0?1:goodsinfo.minOrderNum"
          :max="goodsinfo.countRestrict==-1?
                          goodsinfo.presellTotal==-1?
                              Number(10000):Number(goodsinfo.presellTotal-goodsinfo.presellUsage)
                          :
                          goodsinfo.presellTotal==-1?
                              goodsinfo.countRestrict
                              :
                              Number(goodsinfo.presellTotal-goodsinfo.presellUsage-goodsinfo.countRestrict)>0
                              ?goodsinfo.countRestrict:Number(goodsinfo.presellTotal-goodsinfo.presellUsage)
                          "
          :step="goodsinfo.minOrderNum<0?1:goodsinfo.minOrderNum"
          step-strictly
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row class="infoNumBox" v-else>
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
         
        <!--  :min="stepNum"  :step="stepNum"-->
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="goodsinfo.minQuantity"
          :max="Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)"
          :step="goodsinfo.minOrderQuantity"
          step-strictly
        ></el-input-number>
      </el-col>
    </el-row>
    <div class="infoBtns"  v-if="activityType==1">
      <!-- 预售 -->
      <div :span="24">
        <span class="infoKey"></span>
        <button v-if="goodsinfo.times==-1" class="shopBtn"  @click="immediateBuy(goodsinfo)">
          立即购买
        </button>
        <button v-else :disabled="Number(goodsinfo.times - goodsinfo.cumsum)==0" :class="Number(goodsinfo.times - goodsinfo.cumsum)==0?'grey shopBtn':'shopBtn'"  @click="immediateBuy(goodsinfo)">
          立即购买
        </button>
        <button v-if="goodsinfo.times==-1"  class="addBtn" @click="addCartFun(goodsinfo)">加入购物车</button>
        <button v-else :disabled="Number(goodsinfo.times - goodsinfo.cumsum)==0" :class="Number(goodsinfo.times - goodsinfo.cumsum)==0?'grey addBtn':'addBtn'" @click="addCartFun(goodsinfo)">加入购物车</button>
      </div>
    </div>

     <div class="infoBtns"  v-else>
       <!-- 限时抢购 判断-->
        <div :span="24">
          <span class="infoKey"></span>
          <button :disabled="Number(goodsinfo.comboTotal-goodsinfo.comboUsage)< 1 || Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)<1  ||TimeBuyFlag" :class="Number(goodsinfo.comboTotal-goodsinfo.comboUsage)< 1 || Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)<1 || TimeBuyFlag?'grey addBtn':'addBtn'"  @click="saveFun(num)">
            立即购买
          </button>
        </div>
      </div>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.info {
  overflow: hidden;
  .infoTit {
    font-size: 20px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    .brandName{
      font-weight: bolder;
      color: #333;
    }
    .tag{
        width:42px;
        text-align: center;
          height: 20px;
          // padding: 0 5px;
          line-height: 19px;
          margin-top: 2px;
          border-width: 1px;
          font-size:14px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
        margin-right:3px;
          &.titColor{
            color:#fff
          }
           &.radiusBox{
            border-radius:50%;
          }
          &.bgRed{
             background-color: #DD1F1F;
              border-color: #DD1F1F;
          }
          &.bgBlue{
              background-color: #409eff;
              border-color: #409eff;
          }
          &.bgGreen{
            background-color:  #52C41A;
              border-color:  #52C41A;
          }
          &.bgOrange{
             background-color:#FF7A45;
               border-color: #FF7A45;
          }
    }
  }
  .DecVOBox{
    margin-left:35px ;
    margin-bottom: 10px;
    .DecVOLeft{
      float: left;
      font-weight: bold;
      em {
        border-radius:2px;
        padding:2px 6px;
        font-size:15px;
        background: crimson;
        color: #000;
        margin-right: 10px;
      }
    }
    .DecVORight{
      float: left;
      p{
        margin-bottom: 5px;
        font-size:14px;
        color: crimson;
      }
    }
  }
  .infoMsg {
    color: #FFD303;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
        margin-top: 5px;
  }
  span.infoKey {
    width: 86px;
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
  .downBtn{
    cursor: pointer;
    &:hover{
         color: #FFD303;
    }
  }
  .infoNumBox {
    padding: 0px 10px;
    margin-bottom: 25px;
  }
  .infoBtns {
    padding: 0px 10px;
    z-index: 9999;
    position: relative;
    margin-bottom: 20px;
    button {
      width: 150px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 0;
      padding: 0;
      &.addBtn {
        background: #FFD303;
        color: #000;
        margin-left: 20px;
        border: 1px solid #FFD303;
        outline: none;
      }
      &.shopBtn {
        background: #ff3333;
        color: #fff;
        border: 1px solid #ff3333;
        outline: none;
      }
      &.grey{
         background: #d4d2cf;
          color: #333;
          border: 1px solid #d4d2cf;
      }
    }
  }
  .infoParameter {
    padding: 0px 10px 25px;
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 20px;
    color: #333;
    .del{
       color: #666;
      font-size:12px;
    }
     span.price {
      color: #f43c38;
      font-size: 16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    div{
      float: left;
      width: 50%;
       margin-bottom: 15px;
        &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    // .el-col {
    //   margin-bottom: 15px;
     
    // }
  }
  .countdownBox{
    margin-bottom: -10px;
    background:linear-gradient(to right, #EC1042 , #f7f4f4 );
    height: 40px;
    line-height: 40px;
      .countdownMsg{
          padding-left: 20px;
          color: #000;
          font-size: 16px;
      }
      .countdownDiv{
        float: right;
         padding-right: 20px;
      }
    }
  .infoBox {
    background: #f6f6f6;
    padding: 20px 10px 0 10px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top:10px;
    
    .el-col {
      margin-bottom: 25px;
      &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom:25px;
      }
    }

    span.price {
      color: #f43c38;
      font-size: 16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    span.infoTag {
      border: 1px solid #FFD303;
      color: #FFD303;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0 10px;
    }
    span.infoDes {
      color: #666;
    }
  }
}
</style>
<script>
const ProCountDown = () => import("components/product/ProCountDown.vue");
import {AddCart,DownloadFirstTest} from "api/product.js"
import { mapState,mapMutations,mapGetters} from "vuex";
export default {
  name: "GoodsInfo",
  data() {
    return {
      // num:0,
      stepNum:1,
      num:1,
      promotionMap:[],
      subTitle:'',
      subTotal:0,
       endTime:'',
        startTime:'',
        TimeBuyFlag:false,

        comboPrice:0
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
    ActivityType:{
      type:Number
    }
  },
  components: {
    ProCountDown
  },
  computed: {
    //计算属性可实时更新购物车数量
   CarGoodsNum(){
     return this.$store.getters.CarGoodsNum
   },
    ...mapState({
      goodsnum: state => state.carGoodsNum,
    })
  },
  methods: {
    // 限时购单独成单
     saveFun(num){
      let datailsArr=[];
      this.goodsinfo.productVOList.forEach(item => {
         datailsArr.push({
            goodNum : item.goodNum,
            // goodsCount:item.minOrderNum*num,
            goodsCount:this.num,
            goodsId :item.id,
            goodsType :9,
            isRecentExpiration : 0,
            // originalPrice :item.price,
            originalPrice :this.comboPrice,
         })
      });
      let datas={
        details: datailsArr,
        orderType:7,
        promotionId:this.goodsinfo.id,
        purchaseNum :num,
      }
      console.log(datas)
      sessionStorage.setItem('comBo',JSON.stringify(datas))
      // // 单独成单，跳订单结算,query:{ids:moreIds}}
      this.$router.push({ name: "GoodsAmount",query:{id:"combo"}});
    },
     Download(goodsId){
        // 显示loading
        this.$loading({ fullscreen: true ,background:'#ffffff45'})
      //  alert(goodsId)
         DownloadFirstTest(goodsId).then(res=>{
           if(res.data.code==400){
               this.$message({
                    message:res.data.msg,
                    type: 'warning'
                  });
                // 关闭loading
                this.$loading().close();
           }else{
            let href=res.data.data;
              // 关闭loading
            this.$loading().close();
            window.open(href, '_blank', 'toolbar=yes, width=900, height=700')
           }
          
     
        })
    },
     ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
     ...mapGetters({
        typeQuantity:"typeQuantity"
    }),
    handleChange(value) {
      // console.log(value)
      // value
      this.goodsinfo.numPriceDetailVOList.forEach(item=>{
        if(value>=item.num){
          // console.log(value)
          // console.log(item.num)
          // console.log(item.price)
          this.comboPrice=item.price 
        }else{
          return false
        }
      }) 
    },
   addCartFun(data) {
       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
      // {"isRecentExpiration":0,"quantity":10,"productId":1}
      const addcartDate=[];
      addcartDate.push({
        // isRecentExpiration:data.isRecentExpiration,
        isRecentExpiration:0,
        quantity:this.num,
        productId:data.id,
        goodNum:data.goodNum,
        cartType : 2,
        deliveryTime:data.presellDeliveryDate,// 到货日期   发货时间:
        promotionId:data.promotionId//促销ID
      })
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });
            let count=this.num
            this.setCarGoodsNum(count);
        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    },
    immediateBuy:function(data) {

      console.log('jaiuhuhih',data)
       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'未认证用户不能购买',
              type:"warning"
          })
          return false
      }
      const addcartDate=[];
      addcartDate.push({
        isRecentExpiration:0,
        quantity:this.num,
        productId:data.id,
        goodNum:data.goodNum,
        cartType : 2,
        deliveryTime:data.presellDeliveryDate,// 到货日期   发货时间:
        promotionId:data.promotionId//促销ID
      })
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });
            let count=this.num
            this.setCarGoodsNum(count);
            this.$router.push({ name: "Cart" });

        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    }
  },
  mounted() {
  },
  watch: {
    goodsinfo: function() {
    },
  },
  created() {
    this.activityType=this.ActivityType
    if(this.activityType==1){
        if(this.goodsinfo.minOrderNum<0){
          this.num=1
        }else{
          this.num=this.goodsinfo.minOrderNum
        }
        this.promotionMap=this.goodsinfo.promotionMap
    }else{
      // 限时购详情
       console.log('限时购详情',this.goodsinfo)
          this.promotionMap=this.goodsinfo.promotionMap

this.comboPrice=this.goodsinfo.comboPrice 

         this.goodsinfo.productList.forEach(item=>{
            this.subTitle+=item.name+item.perQuantity+item.unit+','
          })
          this.goodsinfo.productVOList.forEach(item=>{
            this.subTotal+=item.originalPrice*item.minOrderNum
          })
          this.subTitle=this.subTitle.substring(0,this.subTitle.length-1)
          this.num=1,
          console.log(this.goodsinfo.createTime)
          this.endTime=this.goodsinfo.endDate
          this.startTime=this.goodsinfo.beginDate

            let date = new Date();
           let now = date.getTime();
           let  startTime=new Date(this.startTime).getTime()-now;
           if(startTime>0){
            //  还未开始
             this.TimeBuyFlag=true 
           }else{
              this.TimeBuyFlag=false
           }
          // this.endTime=moment(new Date(Date.now()+10*1000)),
          // this.startTime=moment(new Date(Date.now()))
    }
  
  },
};
</script>
